
.list-item.placeholder {
  visibility: hidden;
}

.header {
  margin-top: 20px;
  margin-left: -30px !important;
  margin-right: -30px !important;

  background-color: var(--color-2);
  color: var(--color-3);

  padding-top: 10px;
  padding-bottom: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  font-weight: 700;
}

.session-link {
  color: var(--color-3);

  display: flex;
  justify-content: left;
  align-items: center;
  vertical-align: middle;

  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 0px;
  margin-left: -30px !important;
  margin-right: -30px !important;
  padding-left: 15px;

  border-bottom: 1px solid var(--color-6);
}

a {
  color : var(--color-3);
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

a:link {
  color : var(--color-3);
  text-decoration: none;
}

a:visited {
  color : var(--color-3);
  text-decoration: none;
}

a:hover {
  color : var(--color-3);
  text-decoration: underline;
}

a:active {
  color : var(--color-3);
  text-decoration: underline;
}

.button-no-button {
  all: unset;
  
  padding-bottom: 4px !important;
  padding-left: 10px !important;
}

.button-no-button:focus {
  all: unset;
  cursor: pointer;
}

.create-session, .create-session:focus {
  padding: 5px 10px 5px 10px !important;
  color: var(--color-5) !important;

  margin-top: 10px;
  margin-bottom: 20px;
  
  background-color: var(--color-3) !important;
  font-weight: 600;
  font-size: 13px;
  border-radius: 3px;
  border: 1px solid var(--color-4);

  cursor: pointer;
}

.session-link-item {
  font-weight: 500;
}