.select-dropdown {
    background-color: var(--color-2) !important;
    color: var(--color-3) !important;
    border-radius: 1px;
    height: 1.6em;
    width:unset;
    border: 0px !important;
    padding: 1px 6px 1px 6px;
    margin: 0px 0px 0px 5px;
}

.select-dropdown:focus {
    border-color: var(--color-5) !important;
    box-shadow: 0 0 0 0.2rem rgba(70 70 70 / 15%);
}

.select-option {
    background-color: var(--color-3) !important;
    color: var(--color-5) !important;
}