.expenses-table {
    border-collapse: separate;
    border-spacing: 0 15px; 
    width: 100%;
}

.expenses-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 2px;
    padding-right: 2px;
    font-weight: 500;
    text-align: center;
}

.expenses-table td {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 2px;
    padding-right: 2px;
    text-align: center;
    vertical-align: middle;
}

.expenses-table tr td:first-child {
    padding-left: 12px;
}


/* space after head row 
.expenses-table tbody tr:first-child td {

} */

/* head row border */
.expenses-table th{
    border-top: 1px solid var(--color-7); 
    border-bottom: 1px solid var(--color-7); 

    border-right: 1px solid var(--color-7); 
}
.expenses-table th:first-child{
    border-left: 1px solid var(--color-7); 
    border-radius:5px 0 0 5px;
}
.expenses-table th:last-child{
    border-right: 1px solid var(--color-7); 
    border-radius:0 5px 5px 0;
}

.expenses-table tbody tr {
    border-collapse: separate;
    background-color: var(--color-4);
    border-radius:5px;
}