
.skewed-box {
    padding: 0px;
    background-color: var(--color-3);
    height: 56px;
    transform: skew(-30deg);
    width: 250px;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    display: flex;
    color: var(--color-5);
    font-weight: 600;
}

.link-no-link a {
    color : var(--color-5);
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
  }

  .link-no-link a:link {
    color : var(--color-5);
    text-decoration: none;
  }

  .link-no-link a:visited {
    color : var(--color-5);
    text-decoration: none;
  }

  .link-no-link a:hover {
    color : var(--color-5);
    text-decoration: none;
  }

  .link-no-link a:active {
    color : var(--color-5);
    text-decoration: none;
  }