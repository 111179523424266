

#logo-panel {
    position: relative;
    display: inline-block;
    height: 56px;
    width: 100%;
    background-color: var(--color-1);
    box-shadow: 0 0px 20px rgba(0, 0, 0, 1);
    z-index: 10;
}


#first-panel {
    position: relative;
    display: inline-block;
    height: 900px;
    width: 100%;


}


.background-image {
    position: absolute;
    top: 0; bottom: 0; right: 0; left: 0;  /* follow the parent's edges */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;

}


.typist-text {
    text-align: left;
    font-size: 60px;
    font-weight: 900;
    color: var(--color-3);
}

.hero-button, .hero-button:hover, .hero-button:active, .hero-button:focus {
    all:unset;
    cursor:pointer;
    
    background-color: var(--color-2);
    padding: 15px;
    border-radius: 30px;
    font-size: 25px;
    color: var(--color-3);
    text-align: center;
    font-weight: 600;
    width:250px;
    box-shadow: 0 0px 20px rgba(0, 0, 0, 1);
}

.launch-date {
    color: var(--color-7);
    margin-top: 15px;
    font-size: 20px;
    font-weight: 500;
    font-style: italic;
}

.user-review-box {
    display: flex;
    background-color: rgba(0,0,0, 25%);
    border-radius: 10px;
    padding: 25px;
}


.user-pic {
    flex: 1;
    margin-right:35px;
}

.user-review {
    flex: 5;
    font-weight: 200;
    font-style: italic;
    color: var(--color-7);
    text-align: left;
    vertical-align: center;
    display:flex;


    justify-content: center;
    flex-direction: column;
    
}



.second-panel {
    position: relative;
    display: inline-block;
    width: 100%;
    background-color: var(--color-2);
    box-shadow: 0 0px 20px rgba(0, 0, 0, 1);
    z-index: 10;

    height: 36px;
}



.how-its-working {
    color: var(--color-5);
    font-size: 56px;
}








