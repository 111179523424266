/* these make sure it can work in any text element */
.inline-text_copy--active,
.inline-text_input--active {
  font: inherit;
  color: inherit;
  text-align: inherit;
  padding: 0;
  background: none;
  border: none;
  /* border-bottom: 1px dashed #999999; */
  outline: none;

  min-height: 1.55em;
  min-width: 3em;
}

.inline-text_copy--active {
  cursor: pointer;
}

.inline-text_copy--hidden,
.inline-text_input--hidden {
  display: none !important;
}

.inline-text_input--active {
  /* border-bottom: 1px solid #666666; */
  border-bottom: 1px dashed #999999;
  text-align: left;
}

.inline-text_input {
  margin-bottom: -1px;
}

.inline-text_copy {
  display: inline-block;
  margin-bottom: -6px;
}

.inline-text {
  display: inline-block !important;
}


/* Custom */
.add-new-row {
  background-color: unset !important;
  border-color: rgba(0,0,0, 0.3);
  border-style: dashed;
}

.add-new-row td  {
  border-top: 1px;
  border-bottom: 1px;
  border-right: 0px;
  border-left: 0px;

  border-style: inherit;
  border-color: inherit;
}

.add-new-row td:first-child  {
  border-top: 1px;
  border-bottom: 1px;
  border-right: 0px;
  border-left: 1px;

  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  border-style: inherit;
  border-color: inherit;

}

.add-new-row td:last-child  {
  border-top: 1px;
  border-bottom: 1px;
  border-right: 1px;
  border-left: 0px;

  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  border-style: inherit;
  border-color: inherit;
}

.custom-inline .inline-text_copy {
  border-bottom: 1px dashed #999999;
}

.custom-inline2 .inline-text_copy {
  border-bottom: 1px dashed #999999;
  margin-bottom: -8px;
}