.App {
  /* text-align: center; */
  font-family: 'Montserrat', sans-serif;
}
/*

.App-main {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(80px + 2vmin);
  color: white;
}

.App-rozliczto {
  color: rgb(71, 187, 56);
  margin-left: 35px;
  margin-right: 35px;
}
*/
.Typist .Cursor {
  display: inline-block;
}

.Typist .Cursor--blinking {
    opacity: 1;
    animation: blink 1s linear infinite; 
}



@keyframes blink {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }


    /*
    :root {
      --color-1: #1ed760;
      --color-2: #1db954;
      --color-3: #ffffff;
      --color-4: #f5f5f5;
      --color-5: #464646;
      --color-6: #32d2ae;
    }*/
:root {
  --color-1: #00c59a;
  --color-2: #009776;
  --color-3: #ffffff;
  --color-4: #f5f5f5;
  --color-5: #464646;
  --color-6: #32d2ae;
  --color-7: #ebebeb;
}