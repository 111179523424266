.session-header {
    height: 200px;
}

.header-rounded-box {
    background-color: var(--color-1);
    padding: 15px;
    border-radius: 30px;
    font-size: 25px;
    color: var(--color-3);
    text-align: center;
    font-weight: 400;
}

.header-description {
    color: var(--color-5);
    text-align: center;
    font-weight: 200;
    font-style: italic;
}
