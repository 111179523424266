.balance-table {
    width: 50%;
}

.balance-table th {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 2px;
    padding-right: 2px;
    font-weight: 500;
    text-align: center;
}

.balance-table td {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 2px;
    padding-right: 2px;
    text-align: center;
    vertical-align: middle;
}

.amount-class {
    color: var(--color-2);
    font-weight: 700;
}