.inner-container {
    max-width: 70%;
}
  
body {
    background-color: var(--color-3);
    color: var(--color-5);
}

.center-piece-box {
    box-shadow: 0 0px 27px rgba(0, 0, 0, 0.11);
    border-radius: 15px;
}

.left-panel {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: var(--color-1);
}


.navitem {
    margin: 8px;

    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: 400;
}

.navitem .nav-link {
    color: var(--color-2) !important;
    background-color: transparent;
    border-radius: 0;

    border: 1px solid var(--color-2);

    padding: 4px 25px 4px 25px;
}

.navitem .nav-link.active {
    color: var(--color-3) !important; 
    background-color: var(--color-2);
    border-radius: 0;
    font-weight: 700;

}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

